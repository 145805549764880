<template>
    <div class="contrast">
        <div class="padd20 borbut20" style="position: relative;">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">手机号：</div>
                    <el-input v-model="mobile" placeholder="请输入内容" class="params" @keyup.enter.native="onSearch()"></el-input>
                </div>
                <div class="searchdan">
                    <div class="title">企业名称：</div>
                    <el-input v-model="remark" placeholder="请输入内容" class="params" @keyup.enter.native="onSearch()"></el-input>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <div class="TableList padd20">
            <div class="duoshao">
                <div class="title">对比店铺</div>
                <!-- <div class="lvtotal">
                    总条数：{{lvtotal}} 条

                </div> -->
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="CompanyList" style="width: 100%">
                <el-table-column prop="id" label="id"></el-table-column>
                <el-table-column prop="remark" label="企业名称"></el-table-column>
                <el-table-column prop="company_name" label="主体名"></el-table-column>
                <el-table-column prop="your_kind_balance" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" plain @click="onRemDetails(scope.row.company_name)">对比店铺列表</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="ongetCompanyList" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
        </div>
        <!-- //对比店铺列表 -->
        <StoreList ref="StoreList"></StoreList>
    </div>
</template>

<script>
import StoreList from './StoreList.vue'
export default {
    data() {
        return {
            paramData:{},//表格参数
            per_page: 20, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页

            // 企业列表
            CompanyList:[],
            remark:'',//企业名称
            mobile:'',//手机号


        };
    },
    components:{
        StoreList
    },
    mounted() {
        this.ongetCompanyList()
    },

    methods: {
        onSearch(){
            this.paramData = {
                remark: this.remark,
                mobile:this.mobile
            }
            this.paramPage = 1
            this.ongetCompanyList()
        },
        //获取企业列表
        ongetCompanyList(){
            this.CompanyList = [];
            var param = this.paramData
            param.page = this.paramPage
            param.per_page = this.per_page
            this.$service.get(this.$api.getCompanyList,param, (res)=> {
                if(res.code == '200'){
                    this.CompanyList = res.data.data
                    this.lvtotal = res.data.total
                }
            })
        },
        // 打开店铺对比
        onRemDetails(item){
            this.$refs.StoreList.onDialog(item)
        }
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>
<style lang="scss" scoped>
</style>
<style lang="scss">
</style>