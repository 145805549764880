<template>
    <el-dialog title="商品销量Top10" :visible.sync="Dialog" width="1420px"  class="class-dialog" :before-close='onCancel' modal-append-to-body append-to-body>
        <div class="dialog-inside conStoreList">
            <el-table ref="filterTable" :data="VolumeTopTenList" style="width: 100%">
                <el-table-column prop="id" label="商品id"></el-table-column>
                <el-table-column prop="name" label="商品图片">
                    <template slot-scope="scope">
                        <img :src="scope.row.shop_img" alt="" style="width:80px;height:80px">
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="商品标题" width="300">
                    <template slot-scope="scope">
                        <a :href="scope.row.shop_link" target="_blank" rel="noopener noreferrer" :title="scope.row.shop_title">{{scope.row.shop_title}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="of_company" label="商品上新时间">
                    <template slot-scope="scope">
                        {{dateFtt("yyyy-MM-dd",new Date(scope.row.online_time))}}
                    </template>
                </el-table-column>
                <el-table-column prop="shop_num" label="商品价格">
                    <template slot-scope="scope">
                        {{scope.row.price == scope.row.price_max ? scope.row.price : scope.row.price + '~' + scope.row.price_max}}
                    </template>
                </el-table-column>
                <el-table-column prop="today_volume" label="昨日销量"></el-table-column>
                <el-table-column prop="three_total" label="三日销量"></el-table-column>
                <el-table-column prop="seven_total" label="七日销量"></el-table-column>
                <el-table-column prop="sales_volume" label="总销量"></el-table-column>
                <!-- <el-table-column prop="your_kind_balance" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" plain @click="onShopTop(scope.row.id)">商品销量Top10</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            Dialog:false,
            // of_company:'',//主题名称
            VolumeTopTenList:[],//店铺列表
        };
    },
    
    mounted() {
        
    },

    methods: {
        //打开弹窗并且调用参数
        onDialog(id){
            // this.of_company = item
            this.ongetStoriesShopSalesVolumeTopTen(id)
            this.Dialog = true
        },
        ongetStoriesShopSalesVolumeTopTen(id){
            var param = {
                store_id : id
            }
            this.$service.get(this.$api.getStoriesShopSalesVolumeTopTen,param, (res)=> {
                if(res.code == '200'){
                    this.VolumeTopTenList = res.data
                }
            })
        },
        //弹窗关闭调用
        onCancel(){
            this.Dialog = false
            this.VolumeTopTenList = []
        },
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>