<template>
    <el-dialog title="对比店铺列表" :visible.sync="Dialog" width="1520px"  class="class-dialog" :before-close='onCancel' modal-append-to-body>
        <div class="dialog-inside conStoreList">
            <el-table ref="filterTable" :data="AccountStoriesList" style="width: 100%">
                <el-table-column prop="id" label="店铺id"></el-table-column>
                <el-table-column prop="name" label="店铺logo">
                    <template slot-scope="scope">
                        <img :src="scope.row.logo" alt="" style="width:80px;height:80px">
                    </template> 
                </el-table-column>
                <el-table-column prop="name" label="店铺名">
                    <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank" rel="noopener noreferrer">{{scope.row.name}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="of_company" label="店铺主体">
                    <template slot-scope="scope">
                        {{scope.row.of_company ? scope.row.of_company : '--'}}
                    </template>
                </el-table-column>
                <el-table-column prop="mobile" label="手机号"></el-table-column>
                <el-table-column prop="mobiles" label="手机号2">
                    <template slot-scope="scope">
                        {{scope.row.mobiles ? scope.row.mobiles : '--'}}
                    </template>
                </el-table-column>
                <el-table-column prop="shop_num" label="店铺商品数"></el-table-column>
                <el-table-column prop="shop_num" label="更新时间">
                    <template slot-scope="scope">
                        {{dateFtt("yyyy-MM-dd",new Date(scope.row.updated_at))}}
                    </template>
                </el-table-column>
                <el-table-column prop="today_volume" label="昨日销量"></el-table-column>
                <el-table-column prop="three_sales" label="三日销量"></el-table-column>
                <el-table-column prop="seven_sales" label="七日销量"></el-table-column>
                <el-table-column prop="sales_volume" label="总销量"></el-table-column>
                <el-table-column prop="your_kind_balance" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" plain @click="onShopTop(scope.row.id)">商品销量Top10</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <ShopTop ref="ShopTop"></ShopTop>
    </el-dialog>
</template>

<script>
import ShopTop from './ShopTop.vue'
export default {
    data() {
        return {
            Dialog:false,
            // of_company:'',//主题名称
            AccountStoriesList:[],//店铺列表
        };
    },
    
    components:{
        ShopTop
    },
    mounted() {
        
    },

    methods: {
        onShopTop(id){
            this.$refs.ShopTop.onDialog(id)
        },
        //打开弹窗并且调用参数
        onDialog(item){
            // this.of_company = item
            this.ongetTopCorporateAccountStoriesList(item)
            this.Dialog = true
        },
        ongetTopCorporateAccountStoriesList(item){
            var param = {
                of_company : item
            }
            this.$service.get(this.$api.getTopCorporateAccountStoriesList,param, (res)=> {
                if(res.code == '200'){
                    this.AccountStoriesList = res.data
                }
            })
        },
        //弹窗关闭调用
        onCancel(){
            this.Dialog = false
            this.AccountStoriesList = []
        },
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
.conStoreList{
    padding-bottom: 20px;
}
</style>