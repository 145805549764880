import { render, staticRenderFns } from "./ShopTop.vue?vue&type=template&id=2cb371bf&scoped=true&"
import script from "./ShopTop.vue?vue&type=script&lang=js&"
export * from "./ShopTop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb371bf",
  null
  
)

export default component.exports